import { useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  IconButton,
} from "@mui/material";
import LabelValuesList from "./LabelValuesList";
import { Close } from "@mui/icons-material";


export default function LabelList({ labels, onFilterClick }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState("");
  return (
    <>
      {/* <FixedSizeList
        height={400}
        width={360}
        itemSize={46}
        itemCount={200}
        overscanCount={5}
      >
        {renderRow(labels)}
      </FixedSizeList> */}
      <List dense disablePadding>
        {Object.keys(labels).map((value, index) => {
          return (
            <MenuItem
              key={index}
              onClick={(event) => {
                setSelectedLabel(value);
                setAnchorEl(event.target);
              }}
            >
              <Box display={"flex"} width={"100%"}>
                <ListItemText
                  sx={{ flexGrow: 1, textAlign: "left" }}
                  style={{
                    wordBreak: "break-all",
                    wordWrap: "break-word",
                  }}
                >
                  {value}
                </ListItemText>
              </Box>
            </MenuItem>
          );
        })}
      </List>
      <Popover
        open={
          Boolean(anchorEl) &&
          selectedLabel !== "" &&
          labels.hasOwnProperty(selectedLabel)
        }
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => {
          setSelectedLabel("");
          setAnchorEl(null);
        }}
      >
        <IconButton
          style={{
            cursor: "pointer",
            float: "right",
            marginTop: "5px",
            marginRight:"2px",
            width: "20px",
          }}
          onClick={() => {
            setSelectedLabel("");
            setAnchorEl(null);
          }}
        >
          <Close />
        </IconButton>
        {labels.hasOwnProperty(selectedLabel) ? (
          <>
            <LabelValuesList
              parentLabel={selectedLabel}
              childLabels={labels[selectedLabel]}
              onClick={(value) => {
                onFilterClick(value);
              }}
            />
          </>
        ) : (
          <></>
        )}
      </Popover>
    </>
  );
}
