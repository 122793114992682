import {
    AppBar,
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Toolbar
} from "@mui/material";
import logo from './../Full Logo.png'
import QueryListModal from "./QueryListModal";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteQuery,
    fetchClusters, onClusterSelected,
    onOpenQuery, selectClusterId,
    selectClusters,
    selectQueries,
    selectQuery,
    updateQuery
} from "../features/explorer/explorerSlice";
import {AddAlertSharp, DataArraySharp, SaveSharp} from "@mui/icons-material";
import {useEffect, useState} from "react";
import  {Auth} from "../lib/url";
import {useLocation, useNavigate} from "react-router-dom";
import {emailSelector, tokenSelector, uidSelector} from "../features/authentication/AuthSlice";

export default function Navigation({userId, auth}) {
    const dispatch = useDispatch()
    const queries = useSelector(selectQueries)
    const query = useSelector(selectQuery)
    const [openQuerySaveModal, setOpenQuerySaveModal] = useState(false)
    const [openQueryRepoDrawer, setOpenQueryRepoDrawer] = useState(false)
    const uid = useSelector(uidSelector)
    const email = useSelector(emailSelector)
    const token = useSelector(tokenSelector)
    const clusters = useSelector(selectClusters)
    const selectedCluster = useSelector(selectClusterId)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (uid === undefined || uid === '' || email === undefined || email === '' || token === undefined || token === '') {
        } else {
            dispatch(fetchClusters({uid: uid, email: email, 'auth-token': token}))
        }
    }, [uid, token, email, selectedCluster])
    return (
        <Box sx={{flexGrow: 1}}>
            <AppBar position={"static"} color={"transparent"}>
                <Toolbar sx={{ backgroundImage:"linear-gradient( to right,  #006675 ,#30A56E)"}} title={"Spike"}>
                    <Box sx={{justifyContent: 'flex-start'}}>
                        <img title="Spike_Logo" width={170} src={logo} alt={'logo'} style={{cursor:'pointer'}} onClick={()=>window.location.reload()}/>
                    </Box>

                    {location.pathname !== '/login' &&
                        <Box sx={{flexGrow: 2, display: 'flex', justifyContent: 'flex-end'}}>

                            <FormControl size={"small"} sx={{color: "#fff"}}>
                                <InputLabel sx={{color: "#fff"}}>Clusters</InputLabel>
                                <Select sx={{color: "#fff"}} fullWidth
                                        value={clusters.length === 0 ? -1 : clusters[0]?.cluster_name}
                                        label="Clusters"
                                        disabled={clusters.length === 0}
                                        onChange={(event, child) => {
                                            dispatch(onClusterSelected(event.target.value))
                                        }}>
                                    {clusters.length === 0 &&
                                        <MenuItem disabled value={-1}>No Clusters Found</MenuItem>}
                                    {clusters?.map((cluster, index) => {
                                        return <MenuItem key={index}
                                                         value={cluster.cluster_name}>{cluster.cluster_name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                            <Button sx={{color: "#fff"}} startIcon={<DataArraySharp/>} size={"small"}
                                    onClick={(event) => {
                                        setOpenQueryRepoDrawer(true)
                                    }}>Query Repo</Button>
                            {/* {
                                location.pathname !== "/alerts" ?
                                    <Button sx={{color: "#fff"}} startIcon={<AddAlertSharp/>} size={"small"}
                                            onClick={(event) => {
                                                navigate('/alerts', {replace: true})
                                            }}>Alerts Config</Button> :
                                    <Button sx={{color: "#fff"}} size={"small"} onClick={(event) => {
                                        navigate('/', {replace: true})
                                    }}>Logs</Button>
                            } */}
                        </Box>}

                </Toolbar>
            </AppBar>

            <QueryListModal open={openQueryRepoDrawer} queries={queries} onOpen={(userQuery) => {
                dispatch(onOpenQuery(userQuery))
            }} onDelete={(query) => {
                dispatch(deleteQuery({auth: {uid: uid, email: email, 'auth-token': token}, userQuery: query}))
            }} history={[]} userId={userId} onEdit={(queryId, query, queryName, userId, shared) => {

                let updateRequest = {
                    queryId: queryId,
                    query: query,
                    queryName: queryName,
                    userId: userId,
                    shared: shared,
                    tags: ''
                };
                dispatch(updateQuery({auth:{uid: uid, email: email, 'auth-token': token}, userQuery: updateRequest}))
            }} onClose={() => {
                setOpenQueryRepoDrawer(false)
            }}/>
        </Box>
    )
}