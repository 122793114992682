import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonGroup,
    Dialog,
    DialogTitle, FormControl,
    Grid,
    IconButton, InputLabel,
    List,
    ListItem, MenuItem, Select,
    TextField
} from "@mui/material";
import {AddSharp, DeleteSharp} from "@mui/icons-material";
import {useEffect, useState} from "react";
import type {AlertConfigData, Auth} from "../../lib/url";
import {useDispatch} from "react-redux";
import {updateConfig} from "./alertsSlice";


function EditKey({disabled, alertKey, onChange}) {

    return <>
        <TextField size={"small"} fullWidth disabled={disabled} label={"key"} value={alertKey}
                   onChange={(event) => {
                       onChange(event.target.value)
                   }}></TextField>
    </>
}

function AlertKeys({disabled, keys, onKeysChanged, onKeyAdded, onKeyRemoved}) {
    const [key, setCurrentKey] = useState('')
    return <>
        <Box sx={{border: 1}} p={1} m={1}>
            {keys.map((value, index) => {
                return <Box key={index} p={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <EditKey disabled={disabled} alertKey={value} onChange={(change) => {
                                onKeysChanged(index, change)
                            }}></EditKey>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton size={"small"} onClick={() => {
                                onKeyRemoved(index, value)
                            }}><DeleteSharp/></IconButton>
                        </Grid>
                    </Grid>
                </Box>
            })}

            <>
                <Box m={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={11}>
                            <TextField size={"small"} fullWidth value={key} onChange={(event) => {
                                setCurrentKey(event.target.value)
                            }}/></Grid>
                        <Grid item xs={1}>
                            <Button size={"small"} startIcon={<AddSharp/>} onClick={() => {
                                onKeyAdded(key)
                                setCurrentKey('')
                            }}>Add</Button></Grid>
                    </Grid>
                </Box>

            </>
        </Box>

    </>
}

function AlertKvPairs({disabled, kvKeys, kvValues, onKvPairChange, onKvPairAdded, onKvPairRemoved}) {
    const [key, setCurrentKey] = useState('')
    const [value, setCurrentValue] = useState('')
    return <>
        <Box sx={{border: 1}} p={1} m={1}>
            <List>
                {kvKeys.map((key, index) => {
                    let value = kvValues[index]
                    return <ListItem key={index} secondaryAction={<IconButton onClick={() => {
                        onKvPairRemoved(key, value, index)
                    }}><DeleteSharp/></IconButton>}>
                        <TextField fullWidth size={"small"} disabled={disabled} margin={"normal"} value={key}
                                   onChange={(event => {
                                       onKvPairChange(index, event.target.value, value)
                                   })}></TextField>
                        <TextField fullWidth size={"small"} disabled={disabled} margin={"normal"} value={value}
                                   onChange={(event => {
                                       onKvPairChange(index, key, event.target.value)
                                   })}></TextField>
                    </ListItem>
                })}
            </List>
            <>
                <Grid container spacing={2}>
                    <Grid item xs={5.5}>
                        <TextField label={"kvKey"} size={"small"} fullWidth value={key} onChange={(event) => {
                            setCurrentKey(event.target.value)
                        }}></TextField>
                    </Grid>
                    <Grid item xs={5.5}>
                        <TextField label={"kvValue"} size={"small"} fullWidth value={value} onChange={(event) => {
                            setCurrentValue(event.target.value)
                        }}></TextField>
                    </Grid>
                    <Grid item xs={1}>
                        <Button onClick={() => {
                            onKvPairAdded(key, value)
                            setCurrentKey("")
                            setCurrentValue("")
                        }}>Add</Button>
                    </Grid>
                </Grid>
            </>
        </Box>
    </>

}

function Rule({isWindowForCreate, userProfile, alertRuleId, alertRule, onSave, onRemove, clusters = []}) {
    const [disabled, isDisabled] = useState(!isWindowForCreate)
    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const [query, setQuery] = useState('')
    const [keys, setKeys] = useState([])
    const [kvKeys, setkvKeys] = useState([])
    const [kvValues, setkvValues] = useState([])
    const [alertOnCount, setAlertOnCount] = useState(5)
    const [alertDuration, setAlertDuration] = useState('')
    const [summary, setSummary] = useState('')
    const [description, setDescription] = useState('')
    const [clusterId, setClusterId] = useState(clusters.length === 0 ? -1 : clusters[0].id)

    useEffect(() => {
        if (alertRule !== undefined) {
            setId(alertRule.id)
            setName(alertRule.name)
            setQuery(alertRule.query)
            setKeys(alertRule.keys)
            setkvKeys(alertRule.kvKeys)
            setkvValues(alertRule.kvValues)
            setAlertDuration(alertRule.alertDuration)
            setAlertOnCount(alertRule.alertOnCount)
            setSummary(alertRule.summary)
            setDescription(alertRule.description)
        }
        if (alertRuleId !== undefined) {
            setId(alertRuleId)
        }
        if (isWindowForCreate && clusters.length > 0) {
            setClusterId(clusters[0]?.id)
        }

    }, [alertRule])

    function setValue(event) {
        switch (event.target.name) {
            case 'query':
                setQuery(event.target.value)
                break
            case 'summary':
                setSummary(event.target.value)
                break
            case 'description':
                setDescription(event.target.value)
                break
            case 'alertOnCount':
                setAlertOnCount(event.target.value)
                break
            case 'name':
                setName(event.target.value)
                break
            case 'alertDuration':
                if (event.target.value.endsWith("s") || event.target.value.endsWith("m") || event.target.value.endsWith("h"))
                    setAlertDuration(event.target.value)
                break
            default:
                return
        }
    }

    return <form onChange={(event) => setValue(event)} onSubmit={(event) => {
        event.preventDefault()

    }}>
        {isWindowForCreate && <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Age</InputLabel>
            <Select fullWidth size={"small"} name={"clusterId"}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={clusterId}
                    label="Age"
                    onChange={(event) => {
                        setClusterId(event.target.value)
                    }}
            >
                {clusters.length === 0 ?
                    <MenuItem value={-1} disabled>No Cluster</MenuItem> : clusters.map((cluster, index) => {
                        return <MenuItem key={index} value={cluster.id}>{cluster.cluster_name}</MenuItem>
                    })}
            </Select>
        </FormControl>}
        <TextField margin="dense" size={"small"} fullWidth disabled={disabled} value={name} label={"Name"}
                   name={"name"}></TextField>
        <TextField margin="dense" size={"small"} fullWidth disabled={disabled} value={query} label={"query"}
                   name={"query"}></TextField>
        <TextField margin="dense" size={"small"} fullWidth disabled={disabled} value={summary} label={"summary"}
                   name={"summary"}></TextField>
        <TextField margin="dense" size={"small"} fullWidth disabled={disabled} value={description}
                   label={"description"}
                   name={"description"}></TextField>
        <TextField margin="dense" size={"small"} type={"number"} fullWidth disabled={disabled} value={alertOnCount}
                   label={"alertOnCount"}
                   name={"alertOnCount"}></TextField>
        <TextField margin="dense" size={"small"} fullWidth disabled={disabled} value={alertDuration}
                   label={"alertDuration"}
                   name={"alertDuration"}></TextField>


        <Accordion>
            <AccordionSummary>
                Keys
            </AccordionSummary>
            <AccordionDetails>
                <AlertKeys keys={keys} onKeysChanged={(index, key) => {
                    let updatedKeyList = [...keys]
                    updatedKeyList[index] = key
                    setKeys(updatedKeyList)
                    console.log(updatedKeyList)
                }} onKeyAdded={(key) => {
                    let updatedKeyList = [...keys]
                    updatedKeyList.push(key)
                    setKeys(updatedKeyList)
                    console.log(updatedKeyList)
                }} onKeyRemoved={(index, key) => {
                    let updatedKeyList = keys.filter(value => {
                        return value !== key
                    })
                    console.log(updatedKeyList)
                    setKeys(updatedKeyList)
                }} disabled={disabled}></AlertKeys>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary>KV Pairs</AccordionSummary>
            <AccordionDetails>
                <AlertKvPairs kvValues={kvValues} kvKeys={kvKeys} disabled={disabled}
                              onKvPairChange={(index, key, value) => {
                                  let updatedKvKeys = [...kvKeys]
                                  let updatedKvValues = [...kvValues]
                                  updatedKvKeys[index] = key
                                  updatedKvValues[index] = value
                                  setkvValues(updatedKvValues)
                                  setkvKeys(updatedKvKeys)
                              }}
                              onKvPairAdded={(key, value) => {
                                  let updatedKvKeys = [...kvKeys]
                                  let updatedKvValues = [...kvValues]
                                  updatedKvKeys.push(key)
                                  updatedKvValues.push(value)
                                  setkvValues(updatedKvValues)
                                  setkvKeys(updatedKvKeys)
                              }}
                              onKvPairRemoved={(key, value, index) => {
                                  let updatedKvKeys = kvKeys.filter(kvKey => {
                                      return kvKey !== key
                                  })
                                  let updatedKvValues = kvValues.filter(kvValue => {
                                      return kvValue !== value
                                  })
                                  setkvKeys(updatedKvKeys)
                                  setkvValues(updatedKvValues)
                              }}>
                </AlertKvPairs>
            </AccordionDetails>
        </Accordion>

        <Box p={1} display={"flex"} justifyContent={"flex-end"}>
            <ButtonGroup>
                {!disabled && <Button onClick={() => {

                    let newConfig = {
                        name: name,
                        query: query,
                        keys: keys,
                        kvKeys: kvKeys,
                        kvValues: kvValues,
                        alertOnCount: alertOnCount,
                        alertDuration: alertDuration,
                        summary: summary,
                        description: description,
                    };
                    if (isWindowForCreate)
                        newConfig.clusterId = clusterId
                    console.log(newConfig)
                    onSave(newConfig)
                    isDisabled(true)
                }}>Save</Button>}
                {disabled && <Button onClick={() => {
                    isDisabled(false)
                }}>Edit</Button>}
                {!isWindowForCreate && <Button color={"warning"} startIcon={<DeleteSharp/>} onClick={() => {
                    const rule = {
                        id: id,
                        name: name,
                        query: query,
                        keys: keys,
                        kvKeys: kvKeys,
                        kvValues: kvValues,
                        alertOnCount: alertOnCount,
                        alertDuration: alertDuration,
                        summary: summary,
                        description: description
                    }
                    onRemove(rule)
                }}>Delete</Button>}
            </ButtonGroup>
        </Box>
    </form>

}

export function CreateAlertDialog({userProfile, clusters = [], ruleId, open, onClose, onSave}) {

    return <>
        <Dialog open={open} onClose={() => {
            onClose()
        }}>
            <DialogTitle>Add new Alert Rule </DialogTitle>
            <Box p={1}>
                <Rule clusters={clusters} userProfile={userProfile} onSave={(rule) => {
                    onSave(rule)
                }} alertRuleId={ruleId} isWindowForCreate={true}></Rule>
            </Box>
        </Dialog>
    </>
}

export default function AlertsDashboard({alertConfig, auth}: { alertConfig: AlertConfigData, auth: Auth }) {

    const dispatch = useDispatch()
    console.log(alertConfig)
    return <Box>
        {alertConfig !== undefined && alertConfig.rules !== undefined && <>
            {alertConfig.rules.map((value, index) => {
                return <Accordion key={index}>
                    <AccordionSummary>{value.name}</AccordionSummary>
                    <AccordionDetails>
                        <Rule alertRule={value} onSave={(rule) => {
                            let updatedConfig = {...alertConfig}
                            let rules = [...updatedConfig.rules]
                            rules[index] = rule
                            updatedConfig.rules=rules
                            dispatch(updateConfig({auth: auth, alertConfig: updatedConfig}))
                        }} isWindowForCreate={false}
                              alertRuleIndex={index} onRemove={(rule) => {
                            let updatedConfig = {...alertConfig}
                            updatedConfig.rules = updatedConfig.rules.filter((value, idx) => {
                                return idx !== index
                            })
                            dispatch(updateConfig({auth: auth, alertConfig: updatedConfig}))
                        }}></Rule>
                    </AccordionDetails>
                </Accordion>
            })}
        </>}
    </Box>
}