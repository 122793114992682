import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getAuth, signInWithPopup, GoogleAuthProvider, setPersistence} from "firebase/auth";
import {firebaseConfig} from "./firebase.config";
import {initializeApp} from "firebase/app";
import {browserLocalPersistence} from "firebase/auth";
import {SPIKE_HOST} from "../../lib/url";
import {Clients} from "../../lib/url";

type Auth = {
    "auth-token": string,
    uid: string,
    email: string
}
const initialState = {
    uid: '',
    token: '',
    name: '',
    email: '',
    userId: '',
    isAuthenticated: false,
    profile: {},
    interval: -1
}
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
const provider = new GoogleAuthProvider()

export const isUserRegistered = async function async(auth: Auth) {
    try {
        let authResponse = await Clients.AUTH.CURRENT_USER(auth)
        return authResponse.registered
    } catch (er) {
        return false
    }
}

export const authenticateUser = createAsyncThunk('auth/login', async (arg, thunk) => {
    return new Promise((resolve, reject) => {
        setPersistence(auth, browserLocalPersistence).then(() => {
            signInWithPopup(auth, provider).then(async value => {
                let userProfile = await Clients.AUTH.CURRENT_USER({
                    'auth-token': await value.user.getIdToken(),
                    uid: value.user.uid,
                    email: value.user.email
                })
                console.log(userProfile)
                if (userProfile.registered) {
                    let interval = setInterval(() => {
                        thunk.dispatch(refreshToken())
                    }, 2700000);
                    resolve({
                        token: await value.user.getIdToken(),
                        uid: value.user.uid,
                        name: value.user.displayName,
                        isAuthenticated: true,
                        email: value.user.email,
                        userId: userProfile.userProfile.userId,
                        profile: userProfile.userProfile,
                        interval: interval
                    })
                } else {
                    let req = {
                        companyName: '91social',
                        companyUrl: '91social.com',
                        userName: 'pavan',
                        userRole: 'admin',
                        inviteToken: 'dev-invite',
                        contactNumber: '9441655988'
                    }
                    let registerRequest = await fetch(`http://${SPIKE_HOST}/spike/user/register`, {
                        method: 'POST',
                        headers: {
                            'auth-token': await value.user.getIdToken(),
                            'email': value.user.email,
                            'uid': value.user.uid,
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(req)
                    })
                }
            }).catch(err => {
                console.log(err)
                reject(err)
            })
        })

    })

})

export const refreshToken = createAsyncThunk('auth/refresh', async (arg, thunk) => {
    if (auth !== undefined && auth.currentUser !== undefined && !auth.currentUser.isAnonymous) {
        return thunk.fulfillWithValue({
            uid: auth.currentUser.uid,
            name: auth.currentUser.displayName,
            email: auth.currentUser.email,
            token: await auth.currentUser.getIdToken(),
            isAuthenticated: true,
        })
    }
})

export const validateLogin = createAsyncThunk('auth/check', async (arg, thunk) => {
    return new Promise(async (resolve, reject) => {
        auth.onAuthStateChanged(async value => {
            if ((value !== undefined && value !== null) && !value?.isAnonymous) {

                let user = await Clients.AUTH.CURRENT_USER({
                    'auth-token': await value.getIdToken(),
                    uid: value.uid,
                    email: value.email
                })
                console.log(user)

                if (!user.registered) {
                    alert("please register")
                    reject('user not registered')
                }
                let interval = setInterval(() => {
                    thunk.dispatch(refreshToken())
                }, 2700000);
                return resolve({
                    uid: auth.currentUser.uid,
                    name: auth.currentUser.displayName,
                    email: auth.currentUser.email,
                    token: await auth.currentUser.getIdToken(),
                    isAuthenticated: true,
                    userId: user.userProfile.userId,
                    profile: user.userProfile,
                    interval: interval
                })
            } else {
                return reject(value)
            }
        })
    })
})

function updateState(state, action) {
    state.uid = action.payload.uid
    state.name = action.payload.name
    state.token = action.payload.token
    state.isAuthenticated = action.payload.isAuthenticated
    state.email = action.payload.email
    state.userId = action.payload.userId
    state.profile = action.payload.profile
    if ('interval' in action.payload) {
        let val = state.interval
        console.log(`cancelling session refresh ${val}`)
        clearInterval(val)
        state.interval = action.payload.interval
    }
}

const authSlice = createSlice({
    name: 'authSlice',
    initialState: initialState,
    reducers: {
        onLogin: (state, action) => {
            updateState(state, action);
        },
    },
    extraReducers: (builder => {
        builder.addCase(authenticateUser.fulfilled, (state, action) => {
            updateState(state, action)
        })
        builder.addCase(authenticateUser.rejected, (state, action) => {
            console.warn(`unable to authenticate user due to ${action.payload}`)
        })
        builder.addCase(validateLogin.fulfilled, (state, action) => {
            updateState(state, action)
        })
        builder.addCase(validateLogin.rejected, (state, action) => {
            console.warn(`error: ${action.payload}`)
        })
    })
})

export const {onAuthStateChange, onLogin} = authSlice.actions

export const uidSelector = (state) => state.auth.uid
export const tokenSelector = (state) => state.auth.token
export const nameSelector = (state) => state.auth.name
export const isAuthenticatedSelector = (state) => state.auth.isAuthenticated
export const emailSelector = (state) => state.auth.email
export const userIdSelector = (state) => state.auth.userId
export const userProfileSelector = (state) => state.auth.profile
export default authSlice.reducer