import React from 'react';
import './App.css';
import Explorer from "./features/explorer/Explorer";
import Navigation from "./component/Navigation";
import {
    Routes,
    Route,
} from "react-router-dom";
import AlertManager from "./features/alerts/AlertManager";
import Login from "./features/authentication/Login";
import PrivateRoute from "./features/authentication/PrivateRoute";
import {useSelector} from "react-redux";
import {emailSelector, tokenSelector, uidSelector} from "./features/authentication/AuthSlice";

function App() {
    const email = useSelector(emailSelector)
    const uid = useSelector(uidSelector)
    const authToken = useSelector(tokenSelector)
    return (
        <div className="App">
            <Navigation userId={uid} auth={{"auth-token": authToken, email: email, uid: uid}}/>
            <Routes>
                <Route path={"/explorer"} element={
                    <PrivateRoute>
                        <Explorer/>
                    </PrivateRoute>}>
                </Route>
                <Route path={"/"} element={
                    <PrivateRoute>
                        <Explorer/>
                    </PrivateRoute>}>
                </Route>
                <Route path={"/alerts"} element={
                    <PrivateRoute>
                        <AlertManager/>
                    </PrivateRoute>}>
                </Route>
                <Route element={<Login/>} path={"/login"}></Route>
            </Routes>
        </div>
    );
}

export default App;
