export function reconstructQuery(filters = [], queryExpression: string = '') {
    let query = ''
    let extracted = extractFilters(filters, queryExpression, 0);
    let localFilters = extracted.filters
    let expression = extracted.expression
    if (localFilters === undefined) {
        return queryExpression
    }
    for (let filterIndex = 0; filterIndex < localFilters.length; filterIndex++) {
        if (filterIndex === 0) {
            query = query + '{'
        } else {
            query = query + ','
        }
        let filter = localFilters[filterIndex];
        query = query + `${filter.filterKey}${filter.op}"${filter.filterValue}"`
        if (filterIndex === localFilters.length - 1) {
            query = query + '}'
        }
    }
    query = query + expression
    return query
}

export function extractFilters(filters, query: string = '', pos: number) {
    let labelStart = false;
    let inQuotes = false;
    let localFilters = [...filters];
    let expression = ''
    for (let index = pos; index < query.length; index++) {
        let char = query.charAt(index)
        switch (char) {
            case '{': {
                if (index === 0 && char === "{") {
                    labelStart = true
                }
                if (labelStart) {
                    let {filter, skipIndex} = interpolateFilter(query, index)
                    if (skipIndex !== -1 && filter !== undefined) {
                        localFilters.push(filter)
                        console.log(localFilters)
                        index = skipIndex
                    }
                }
                break
            }
            case '}': {
                if (labelStart && !inQuotes) {
                    labelStart = false
                    if (index !== query.length - 1) {
                        expression = query.substring(index + 1, query.length)
                        index = index + expression.length
                    }
                }
                break
            }
            case ',': {
                let {filter, skipIndex} = interpolateFilter(query, index)
                if (skipIndex !== -1 && filter !== undefined) {
                    localFilters.push(filter)
                    index = skipIndex
                    continue
                }
                break
            }
            default:
                switch (char) {
                    case ' ':
                    case '|':
                    case '!':
                        if (index === 0) {
                            expression = query
                        }
                }

        }

    }
    if (localFilters === undefined) {
        return {filters: undefined, expression: expression}
    }
    return {filters: localFilters, expression: expression}
}

export function tokenize(filters: Array<string>, query: string) {
    let extracted = extractFilters(filters, query, 0);
    let expression = extracted.expression;
    if (expression.length > 1) {
        let tokens = expression.split(/\W+/);
        return tokens.filter(value => {
            return value.length>0
        })
    }
    return []
}

export function interpolateFilter(query: string, pos: number) {
    let inQuotes = false
    for (let index = pos; index < query.length; index++) {
        let char = query.charAt(index)

        // eslint-disable-next-line default-case
        switch (char) {
            case '"': {
                inQuotes = !inQuotes;
                break
            }
            case '=':
                if (!inQuotes) {
                    index = index + 1;
                    let value = extractLabelValue(query, pos, index - 1, index)
                    console.log(value)
                    return value;
                }
            case '~':
            case '!': {
                if (!inQuotes) {
                    index = index + 1;
                    let value = extractLabelValue(query, pos, index - 1, index + 1)
                    console.log(value)
                    return value
                }
                break
            }
        }
    }
    return {index: -1}
}

function indexOf(query: string = '', pattern: string = '', pos: number = 0) {
    for (let i = pos; i < query.length; i++) {
        let lengthCheck = query.length - i - pattern.length
        if (lengthCheck > 0) {
            let subQuery = query.substring(i, i + pattern.length);
            if (subQuery === pattern) {
                return i
            }
        }
    }
    return -1
}

function extractLabelValue(query: string, pos: number, opStart: number, opEnd = opStart + 1) {
    console.log(`op: ${query.substring(opStart, opEnd)} value: ${query.substring(opEnd + 1, query.indexOf("\"", opEnd + 1))}`)
    let skipIndex = query.indexOf('"', opEnd);
    if (skipIndex === -1) {
        skipIndex = indexOf(query, '"', opEnd)
    }
    let subQuery = query.substring(opEnd, query.length);
    console.log(subQuery)
    console.log(skipIndex)
    return {
        filter: {
            op: query.substring(opStart, opEnd),
            filterKey: query.substring(pos + 1, opStart),
            filterValue: query.substring(opEnd + 1, query.indexOf('"', opEnd + 1))
        }, skipIndex: skipIndex
    }
}
