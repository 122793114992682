import {Navigate, useLocation} from 'react-router-dom'
import {authenticateUser, checkLogin, isAuthenticatedSelector, validateLogin} from "./AuthSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";

export default function PrivateRoute({children}: { children: JSX.Element }) {

    let location = useLocation()
    const isAuthenticated = useSelector(isAuthenticatedSelector)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(validateLogin())
    }, [])
    return isAuthenticated ? children : <Navigate to="/login" state={{from: location}} replace/>;
}