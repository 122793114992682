import {Button} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {authenticateUser, isAuthenticatedSelector, isUserRegistered, validateLogin} from "./AuthSlice";
import {useEffect} from "react";
import {auth} from "./AuthSlice"
import {useLocation, useNavigate} from "react-router-dom";

export default function Login() {
    const dispatch = useDispatch()
    const location = useLocation()
    let from = location.state?.from?.pathname || "/";
    let navigate = useNavigate();
    const isAuthenticated = useSelector(isAuthenticatedSelector)
    useEffect(() => {
        dispatch(validateLogin())
    }, [])
    useEffect(() => {
        console.log(isAuthenticated)
        if (isAuthenticated) {
            navigate(from, {replace: true})
        }
    }, [isAuthenticated])

    return <>
        <center>
            <Button onClick={() => {
                dispatch(authenticateUser())
            }}>Login with Google</Button>
        </center>
    </>
}