import Highlighter from "react-highlight-words";
import {Box, Table, TableBody, TableCell, tableCellClasses, TableHead, TableRow} from "@mui/material";
import {DateTime} from "luxon";
import {useSelector} from "react-redux";
import {selectFilters, selectQuery} from "../features/explorer/explorerSlice";
import {useEffect, useState} from "react";
import {tokenize} from "../lib/queryUtils";
import './Highlighter.css'

export function Log({log}) {
    const query = useSelector(selectQuery)
    const filters = useSelector(selectFilters)
    const [tokens, setTokens] = useState([])
    useEffect(() => {
        let ex = tokenize(filters, query)
        if (ex!==undefined)
            setTokens(ex)
    }, [query, filters])

    return (
        <Box sx={{whiteSpace:'pre-wrap'}}>
            <Highlighter className="highlighter-pre"
                searchWords={tokens}
                autoEscape={true}
                textToHighlight={log}
            />
        </Box>
    )
}

export default function LogsTableVIew({logs}) {
    return <>
        <Table color={"#000"} size={"small"} padding={"none"} sx={{
            [`& .${tableCellClasses.root}`]: {
                borderBottom: "none"
            }
        }}>
            <colgroup>
                <col width='15%'/>
                <col width='85%'/>
            </colgroup>
            <TableHead>
                <TableRow>
                    <TableCell>_ts</TableCell>
                    <TableCell>Message</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    logs.map((log, index) => {
                        return log.logs.map((value, logIndex) => {
                            return <TableRow key={`${index}-${logIndex}`}>
                                <TableCell>
                                    <p>{DateTime.fromMillis(value._ts / 1000000).toFormat('yy LLL dd HH:mm:s')}</p>
                                </TableCell>
                                <TableCell>
                                    <Log log={value.log}/>
                                </TableCell>
                            </TableRow>
                        })
                    })
                }
            </TableBody>
        </Table>
    </>
}