import {Box, Button, Grid, Popover, TextField} from "@mui/material";
import {PlayArrowSharp, SearchSharp, StopSharp} from "@mui/icons-material";
import DateTimeSelector from "./DateTimeSelector";
import {SaveSharp} from "@mui/icons-material";
import {useSelector, useDispatch} from "react-redux";
import QuerySaveModal from "./QuerySaveModal";
import LabelForm from "./LabelForm";
import {
    addQuery, onFiltersAdded, onFiltersRemoved,
} from "../features/explorer/explorerSlice";
import {
    emailSelector, tokenSelector,
    uidSelector,
    userIdSelector,
} from "../features/authentication/AuthSlice";
import {useState} from "react";

export default function QueryForm({
                                      fromTimeRecord,
                                      toTimeRecord,
                                      query,
                                      labels,
                                      filters,
                                      onQueryChange,
                                      onFromDateChange,
                                      onToDateChange,
                                      onFilterChange,
                                      onSearchClicked,
                                      tailing,
                                      onTailing,
                                      onStopTailing,
                                  }) {
    const userId = useSelector(userIdSelector);
    const dispatch = useDispatch()
    const [saveQueryModal, setOpenQuerySaveModal] = useState(false);
    const uid = useSelector(uidSelector)
    const email = useSelector(emailSelector)
    const token = useSelector(tokenSelector)
    return (
        <>
            <Box flexGrow={1} marginTop={"3px"}>
                <Grid container spacing={1}>
                    <Grid item sm={8} md={8} lg={8} xl={8}>
                        <TextField
                            size={"small"}
                            fullWidth
                            multiline={true}
                            maxRows={4}
                            value={query}
                            placeholder={"Search query"}
                            onChange={(event) => {
                                onQueryChange(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item sm={2} md={2} lg={2} xl={2}>
                        <DateTimeSelector
                            onDateTimeChange={(change) => {
                                onFromDateChange(change);
                            }}
                            timeRecord={fromTimeRecord}
                        />
                    </Grid>
                    <Grid item sm={2} md={2} lg={2} xl={2}>
                        <DateTimeSelector
                            onDateTimeChange={(change) => {
                                onToDateChange(change);
                            }}
                            timeRecord={toTimeRecord}
                        />
                    </Grid>
                    <Grid item sm={12} md={12} xl={12} lg={12}>
                        <Box
                            flexGrow={1}
                            display={"flex"}
                            justifyContent={"flex-end"}
                            //   sx={{ position: "absolute", marginTop: -10.4, marginLeft: "50%" }}
                        >
                            {/* {tailing === undefined || tailing === false ? (
                <Button
                  size={"small"}
                  variant={"contained"}
                  sx={{ marginRight: "0.5rem" }}
                  onClick={() => {
                    onTailing();
                  }}
                  startIcon={<PlayArrowSharp />}
                >
                  Tail
                </Button>
              ) : (
                <Button
                  size={"small"}
                  variant={"contained"}
                  sx={{ marginRight: "0.5rem" }}
                  onClick={() => {
                    onStopTailing();
                  }}
                  startIcon={<StopSharp />}
                >
                  Stop Tail
                </Button>
              )} */}

                            {/* <Button
                size={"small"}
                variant={"contained"}
                startIcon={<SearchSharp />}
                onClick={() => {
                  onSearchClicked();
                }}
              >
                Search
              </Button> */}
                        </Box>
                        <Box display={"flex"} flexGrow={1} justifyContent={"flex-end"}>
                            {/* {console.log(filters)} */}
                            <LabelForm
                                labels={labels}
                                filters={filters}
                                onFilterAdded={(filter) => {
                                    dispatch(onFiltersAdded(filter));
                                }}
                                onFilterDeleted={(filter) => {
                                    dispatch(onFiltersRemoved(filter));
                                }}
                            />
                            <Button
                                variant={"contained"}
                                sx={{marginLeft: "0.5rem"}}
                                size={"small"}
                                startIcon={<SaveSharp/>}
                                onClick={(event) => {
                                    setOpenQuerySaveModal(true);
                                }}
                            >
                                Save Query
                            </Button>
                            <Button
                                size={"small"}
                                variant={"contained"}
                                sx={{marginRight: 1, marginLeft: 1}}
                                startIcon={<SearchSharp/>}
                                onClick={() => {
                                    onSearchClicked();
                                }}
                            >
                                Search
                            </Button>

                            {tailing === undefined || tailing === false ? (
                                <Button
                                    size={"small"}
                                    variant={"contained"}
                                    sx={{marginRight: "0.5rem"}}
                                    onClick={() => {
                                        onTailing();
                                    }}
                                    startIcon={<PlayArrowSharp/>}
                                >
                                    Tail
                                </Button>
                            ) : (
                                <Button
                                    size={"small"}
                                    variant={"contained"}
                                    sx={{marginRight: "0.5rem"}}
                                    onClick={() => {
                                        onStopTailing();
                                    }}
                                    startIcon={<StopSharp/>}
                                >
                                    Stop Tail
                                </Button>
                            )}

                        </Box>

                    </Grid>

                </Grid>
            </Box>

            <QuerySaveModal
                userId={userId}
                open={saveQueryModal}
                query={query}
                filters={filters}
                onSave={(queryId, query, queryName, userId, shared) => {
                    dispatch(
                        addQuery({
                            auth: {uid: uid, email: email, "auth-token": token},
                            userQuery: {
                                query: query,
                                queryName: queryName,
                                userId: userId,
                                shared: shared,
                                tags: "",
                            },
                        })
                    );
                }}
                onClose={() => {
                    setOpenQuerySaveModal(false);
                }}
            />
        </>
    );
}
