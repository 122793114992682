//@flow
import {
    Accordion, AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonGroup, Dialog, DialogTitle, FormControl, InputLabel, MenuItem, Select,
    TextField
} from "@mui/material";
import {AddSharp} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {updateConfig} from "./alertsSlice";
import type {AlertConfigData, Auth} from "../../lib/url";

function Target({isWindowForNew, id, alertTarget, clusters = [], onChange, onRemove}) {
    const [disabled, isDisabled] = useState(!isWindowForNew)
    const [targetId, setTargetId] = useState('')
    const [type, setType] = useState('')
    const [authKey, setAuthKey] = useState('')
    const [targetUrl, setTargetUrl] = useState('')
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [clusterId, setClusterId] = useState(clusters.length === 0 ? -1 : clusters[0].id)
    useEffect(() => {
        if (isWindowForNew) {
            setTargetId(id)
        } else {
            setTargetId(alertTarget.targetId)
            setType(alertTarget.type)
            setAuthKey(alertTarget.authKey)
            setTargetUrl(alertTarget.targetUrl)
            setTitle(alertTarget.title)
            setDescription(alertTarget.description)
        }
    }, [isWindowForNew, targetId, alertTarget])

    function handleFormChange(event) {
        if (disabled)
            return;
        switch (event.target.name) {
            case 'type':
                setType(event.target.value)
                break
            case 'authKey':
                setAuthKey(event.target.value)
                break
            case 'targetUrl':
                setTargetUrl(event.target.value)
                break
            case 'title':
                setTitle(event.target.value)
                break
            case 'description':
                setDescription(event.target.value)
                break
            case 'clusterId':
                console.log(event.target.value)
                break
            default:
                return
        }
    }

    return <>
        <Box>
            <form onChange={(event) => {
                handleFormChange(event)
            }}>
                {isWindowForNew &&
                    <Select name={"clusterId"}
                            value={clusterId}
                            label="ClusterId"
                            onChange={(event, child) => {
                                setClusterId(event.target.value)
                            }} fullWidth size={"small"}>
                        {clusters.length === 0 ?
                            <MenuItem value={-1} disabled>No Cluster</MenuItem> : clusters.map((cluster, index) => {
                                return <MenuItem key={index} value={cluster.id}>{cluster.cluster_name}</MenuItem>
                            })}
                    </Select>}
                <TextField label={"title"} size={"small"} margin={"normal"} disabled={disabled} fullWidth name={'title'}
                           value={title}></TextField>
                <TextField label={"description"} size={"small"} margin={"normal"} disabled={disabled} fullWidth
                           name={'description'}
                           value={description}></TextField>
                <TextField label={"type"} size={"small"} margin={"normal"} disabled={disabled} fullWidth name={'type'}
                           value={type}></TextField>
                <TextField label={"authKey"} size={"small"} margin={"normal"} disabled={disabled} fullWidth
                           name={'authKey'}
                           value={authKey}></TextField>
                <TextField label={"targetUrl"} size={"small"} margin={"normal"} disabled={disabled} fullWidth
                           name={'targetUrl'}
                           value={targetUrl}></TextField>
            </form>
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"}>
            <ButtonGroup>
                {(!isWindowForNew || disabled) && <Button onClick={() => isDisabled(false)}>Edit</Button>}
                {!disabled && <Button onClick={() => {
                    if (isWindowForNew) {
                        onChange({clusterId, targetId, type, authKey, targetUrl, title, description})
                        return
                    }
                    onChange({targetId, type, authKey, targetUrl, title, description})
                }}>Save</Button>}
                {!isWindowForNew && <Button color={"warning"} onClick={() => {
                    onRemove({targetId, type, authKey, targetUrl, title, description})
                }}>Delete</Button>}
            </ButtonGroup>
        </Box>
    </>
}

export function CreateAlertTargetDialog({targetId, clusters = [], open, onClose, onSave, onRemove}) {
    return <>
        <Dialog open={open} onClose={() => {
            onClose()
        }}>
            <DialogTitle>Create New Alert Target</DialogTitle>
            <Box p={1}>
                <Target isWindowForNew={true} clusters={clusters} id={targetId} onChange={(target) => {
                    onSave(target)
                }} onRemove={(target => {
                    onRemove(target)
                })}></Target>
            </Box>
        </Dialog>
    </>
}

export default function AlertTargets({alertConfig, auth}: { alertConfig: AlertConfigData, auth: Auth }) {
    const dispatch = useDispatch()
    const targets = alertConfig.targets
    console.log(targets)
    const [open, setOpen] = useState(false)
    return <>

        {targets !== undefined && targets.map((target, index) => {
            return <Box key={index}>
                <Accordion key={index}>
                    <AccordionSummary>${target.title}</AccordionSummary>
                    <AccordionDetails>
                        <Target isWindowForNew={false} onRemove={(target) => {
                            let updatedConfig = {...alertConfig}
                            updatedConfig.targets = updatedConfig.targets.filter((t, idx) => {
                                return idx !== index
                            })
                            dispatch(updateConfig(auth, updatedConfig))
                        }} alertTarget={target} onChange={(alertTarget) => {
                            let updatedConfig = {...alertConfig}
                            updatedConfig.targets.splice(index,1,alertTarget)
                            dispatch(auth, updatedConfig)
                        }}></Target>
                    </AccordionDetails>
                </Accordion>

            </Box>
        })
        }
        <CreateAlertTargetDialog targetId={"some_id"} open={open} onClose={() => {
            setOpen(false)
        }} onSave={(target) => {
            let updatedConfig = {...alertConfig}
            let targets = [...updatedConfig.targets]
            targets.push(target)
            dispatch(updateConfig)
        }}></CreateAlertTargetDialog>
    </>
}
