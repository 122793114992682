import {
  Box,
  ButtonGroup,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  AddCircleOutlineSharp,
  RemoveCircleOutlineSharp,
  Close,
} from "@mui/icons-material";

export default function LabelValuesList({
  parentLabel,
  childLabels = [],
  onClick,
}) {
  const [filter, setFilter] = useState("");
  const [close,setClose] = useState(true)
  const [filteredLabels, setFilteredLabels] = useState([]);
  useEffect(() => {
    if (filter !== "") {
      setFilteredLabels(
        childLabels.filter((value, index) => {
          return value.includes(filter);
        })
      );
    } else {
      setFilteredLabels(childLabels);
    }
  }, [childLabels, filter]);
  return (
    <>
    <Box
        padding={"1rem"}
        minWidth={"300px"}
        maxWidth={"500px"}
      >
        {/* <IconButton style={{cursor:'pointer', float:'right', marginTop: '5px', width: '20px'}} onClick={() => setClose(false)}>
          <Close />
        </IconButton> */}
        <Typography variant={"h6"}>{parentLabel}</Typography>
        <Typography variant={"subtitle2"}>
          {childLabels.length} filters available
        </Typography>
        <Divider sx={{ marginTop: "5px", marginBottom: "5px" }} />
        {childLabels.length > 5 && (
          <TextField
            size={"small"}
            fullWidth
            value={filter}
            onChange={(event) => {
              setFilter(event.target.value);
            }}
          />
        )}
        <List
          sx={{ marginTop: "10px", maxHeight: "500px" }}
          dense
          disablePadding
        >
          {filteredLabels.map((label, index) => {
            return (
              <ListItem key={index} sx={{ padding: "0rem" }}>
                <Box display={"flex"} width={"100%"}>
                  {index < 20 ? (
                    <>
                      {" "}
                      <ListItemText sx={{ flexGrow: 1 }}>{label}</ListItemText>
                      <ButtonGroup>
                        <IconButton
                          color={"primary"}
                          size={"small"}
                          onClick={() => {
                            onClick({
                              filterKey: parentLabel,
                              op: "=",
                              filterValue: `${label}`,
                            });
                          }}
                        >
                          <AddCircleOutlineSharp sx={{ fontSize: 15 }} />
                        </IconButton>
                        <IconButton
                          color={"warning"}
                          size={"small"}
                          onClick={() => {
                            onClick({
                              filterKey: parentLabel,
                              op: "!=",
                              filterValue: `${label}`,
                            });
                          }}
                        >
                          <RemoveCircleOutlineSharp sx={{ fontSize: 15 }} />
                        </IconButton>
                      </ButtonGroup>
                    </>
                  ) : (
                    ""
                  )}
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Box> 
    </>
  );
}
