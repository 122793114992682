import {Box, Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {isTailConnected, onClose, onConnected, onMessage, tailLogsSelector} from "./TailSlice";
import {useDispatch, useSelector} from "react-redux";
import {TAIL_HOST} from "../../lib/url";
import {emailSelector, tokenSelector, uidSelector} from "../authentication/AuthSlice";
import {Log} from "../../component/LogsTableVIew";
import {selectClusterId} from "./explorerSlice";

export default function Tail({query, maxHeight}) {
    const isConnected = useSelector(isTailConnected)
    const tailLogs = useSelector(tailLogsSelector)
    const uid = useSelector(uidSelector)
    const token = useSelector(tokenSelector)
    const email = useSelector(emailSelector)
    const dispatch = useDispatch()
    const clusterName= useSelector(selectClusterId)
    useEffect(() => {
        console.log(tailLogs)
    }, [tailLogs])

    useEffect(() => {
        let socket = new WebSocket(`wss://${TAIL_HOST}/${clusterName}/loki/api/v1/tail?query=${encodeURI(query)}&&auth-token=${token}&&email=${email}&&uid=${uid}`)

        socket.addEventListener("open", (ev) => {
            dispatch(onConnected({}))
        })
        socket.addEventListener('close', ev => {
            console.log(ev)
            dispatch(onClose({}))
        })
        socket.addEventListener('message', ev => {
            dispatch(onMessage(ev.data))
        })
        socket.addEventListener('error', ev => {
            console.warn(ev)
            socket = new WebSocket(`wss://${TAIL_HOST}/${clusterName}/loki/api/v1/tail?query=${encodeURI(query)}&&auth-token=${token}&&email=${email}&&uid=${uid}`)
        })
        return () => {
            console.log("closed")
            socket.close()
        }
    }, [maxHeight, dispatch])
    return <Box sx={{maxHeight: maxHeight, minHeight: maxHeight}}>

        <Box textAlign={"left"}><p>Status : {isConnected ? "connected" : "not connected"}</p></Box>
        <Box sx={{border: 1, p: 1, textAlign: "left", overflow: "auto"}}>
            <Grid container>
                <Grid item xs={4} md={4} lg={4}>
                    Time
                </Grid>
                <Grid item xs={8} md={8} lg={8}>
                    Message
                </Grid>
                {tailLogs.map((value, index) => {
                    return <Grid key={index} container>
                        <Grid item xs={4} md={3} lg={2}>
                            <p>{value.time}</p>
                        </Grid>
                        <Grid item xs={8} md={9} lg={10}>
                            <Log log={value.line}/>
                        </Grid>
                    </Grid>
                })}
            </Grid>

        </Box>
    </Box>
}
