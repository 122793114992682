import {
    Box,
    Button,
    Checkbox,
    Dialog, DialogActions, DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Popover,
    IconButton,
    TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import {reconstructQuery} from "../lib/queryUtils";
import { Close } from "@mui/icons-material";

export default function QuerySaveModal({
                                           open,
                                           query,
                                           filters,
                                           fromDateRange,
                                           toDateRange,
                                           userId,
                                           queryToModify,
                                           onSave,
                                           onClose
                                       }) {
    const [saveQuery, setSaveQuery] = useState(query)
    const [queryName, setQueryName] = useState("")
    const [shouldSaveRange, setShouldSaveRange] = useState(false)
    const [share, setShare] = useState(false)

    useEffect(() => {
        if (queryToModify !== undefined) {
            setSaveQuery(queryToModify.query)
            setQueryName(queryToModify.queryName)
            setShare(queryToModify.shared === "Y")
        } else {
            let reconstructedQuery = reconstructQuery(filters,query);
            console.log(reconstructedQuery)
            setSaveQuery(reconstructedQuery)
        }
    }, [queryToModify, query, fromDateRange, toDateRange,filters,open])
    return <>
        <Dialog open={open} onClose={() => {
            onClose(false)
        }}>
        
            <DialogTitle>Save Query</DialogTitle>
            <IconButton  onClick={() =>  onClose(false)}>
          <Close style={{cursor:'pointer',float:'right', marginTop: '-60px', width: '20px',marginLeft:'75%'}} onClick={() =>  onClose(false)}/>
        </IconButton>
            <DialogContent>
                <Box minWidth={"450px"}>
                    <TextField  label={"Query"} margin={"normal"} size={"small"} fullWidth value={saveQuery}
                               onChange={(event) => {
                                   setSaveQuery(event.target.value)
                               }} name={"query"}/>
                    <TextField  label={"Query Name"} margin={"normal"} size={"small"} fullWidth value={queryName}
                               onChange={(event) => {
                                   setQueryName(event.target.value)
                               }} name={"queryName"}/>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox checked={shouldSaveRange} onChange={(event, checked) => {
                                    setShouldSaveRange(checked)
                                }
                                } name="saveRange"/>
                            }
                            label="Save Time Ranges"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox checked={share} onChange={(event, checked) => {
                                    setShare(checked)
                                }} name="share"/>
                            }
                            label="Share Query"
                        />
                    </FormGroup>
                </Box>

            </DialogContent>
            <DialogActions >
                <Button onClick={() => {
                    onClose(false)
                }}>Close</Button>
                <Button onClick={() => {
                   
                    if (queryToModify !== undefined && queryToModify.queryId !== undefined) {
                        onSave(queryToModify.queryId, saveQuery, queryName, userId, share ? "Y" : "N", "")
                    } else {

                        onSave("",saveQuery, queryName, userId, share ? "Y" : "N", "")
                    }
                    onClose(false)
                }} autoFocus>Save</Button>
            </DialogActions>
        </Dialog>
    </>
}