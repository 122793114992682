import {useEffect, useState} from "react";
import {Grid, MenuItem, Select, TextField} from "@mui/material";
import {DateTime} from "luxon";
import {TimeUnits} from "../lib/dateTimeUtils";
import {Duration} from "luxon/build/cjs-browser/luxon";


export default function DateTimeInput({
                                          timeRecord,
                                          onChange
                                      }: { timeRecord: { epoch: number, unit: string, interval: number, type: string }, onChange: Function }) {
    const [relativeCount, setRelativeCount] = useState(15)
    const [timeUnit, setTimeUnit] = useState(2)

    useEffect(() => {
        if (timeRecord.type === "Relative") {
            setTimeUnit(TimeUnits.indexOf(timeRecord.unit))
            setRelativeCount(timeRecord.interval)
        }
    }, [timeRecord])

    function handleChange(unit: number, qty: number, callback: function) {
        setRelativeCount(qty)
        setTimeUnit(unit)
        let unitValue = TimeUnits[unit];
        let change = {
            type: 'Relative',
            unit: unitValue,
            interval: qty,
            epoch: DateTime.now().minus(Duration.fromObject({[unitValue]: qty})).toMillis()
        }
        callback(change)
    }

    return (
        <Grid container spacing={1}>
            <Grid item sm={6}>
                <TextField value={relativeCount} onChange={(event) => {
                    handleChange(timeUnit, event.target.value, onChange)
                }}>
                </TextField>
            </Grid>
            <Grid item sm={6}>
                <Select value={timeUnit} onChange={(event) => {
                    handleChange(event.target.value, relativeCount, onChange)
                }}>
                    {
                        TimeUnits.map((value, index) => {
                            return (
                                <MenuItem key={index} value={index}>{value}</MenuItem>
                            )
                        })
                    }
                </Select>
            </Grid>
        </Grid>
    )
}