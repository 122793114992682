
export const SPIKE_HOST = "pulse.episilia.net"
// export const SPIKE_HOST = "dev-pulse.episilia.net"
export const TAIL_HOST = "ingress.episilia.net"
const allowHttps = true

export type Auth = {
    uid: string,
    'auth-token': string,
    email: string
}

export type SearchQuery = {
    active: string,
    queryId: string,
    queryName: string,
    companyId: string,
    userId: string,
    query: string,
    shared: string,
    tags: string,
    id: number,
    startTime: number,
    endTime: number,
    updatedAt: number
}
export type AlertRule = {
    id: string,
    name: string,
    query: string,
    keys: Array<string>,
    kvKeys: Array<string>,
    kvValues: Array<string>,
    alertOnCount: number,
    alertDuration: string,
    summary: string,
    description: string
}
export type AlertTarget = {
    targetId: string,
    type: string,
    authKey: string,
    targetUrl: string,
    title: string,
    description: string
}

export type AlertConfigData = {
    companyId: string,
    clusterName: string,
    clusterId: string,
    targets: Array<AlertTarget>,
    rules: Array<AlertRule>
}
let protocol = `http${allowHttps ? 's' : ''}`;

let extractApiData = async (json) => {
    if ('success' in json && json.success) {
        if ('data' in json) {
            return json.data
        } else {
            return Promise.reject(`expected data property in res ${JSON.stringify(json)}`)
        }
    } else {
        if ('errorId' in json && 'message' in json) {
            return Promise.reject(`error on api data code: ${json.errorId} message: ${json.message}`)
        } else {
            return Promise.reject(`error on api data ${JSON.stringify(json)}`)
        }
    }
}

function transformLogs(logs) {
    let logBlock = []
    let result = logs.data.result

    for (let logIndex in result) {
        let {stream, values} = result[logIndex]
        let logs = []
        for (const logSection in values) {
            let value = values[logSection];
            logs.push({
                _ts: value[0],
                log: value[1]
            })
        }
        logBlock.push({
            labels: stream,
            logs: logs
        })
        logBlock.sort((a, b) => {
            return b.logs[0]._ts - a.logs[0]._ts
        })
        // console.log(logBlock.sort((a,b) => a._ts - b._ts))
    }
    return logBlock
}

let extractLogsFromApi = async (json) => {
    try {
        let logResponse = await extractApiData(json)
        return transformLogs(logResponse)
    } catch (e) {
        return Promise.reject(e)
    }
}

export const URL = {
    SPIKE: {
        LABELS: "",
        LABEL_VALUES: "",
        QUERY: "",
        AUTH: {
            CURRENT_USER: `${protocol}://${SPIKE_HOST}/spike/user/current`
        },
        ALERTS: {
            CONFIG_FOR_CLUSTER_URL: function (clusterId) {
                return `${protocol}://${SPIKE_HOST}/spike/alert/config/get/${clusterId}`
            },
            UPDATE_CONFIG_FOR_CLUSTER: `${protocol}://${SPIKE_HOST}/spike/alert/config/update`
        },
        CLUSTER: {
            LIST: `${protocol}://${SPIKE_HOST}/spike/cluster/list`
        },
        SEARCH: {
            GET_LABELS_URL: (clusterId) => {
                return `${protocol}://${SPIKE_HOST}/spike/search/labels?${new URLSearchParams({
                    'clusterId': clusterId
                })}`
            },
            GET_LABEL_VALUES: (clusterId: string, labelKey: string) => {
                return `${protocol}://${SPIKE_HOST}/spike/search/labelvalues?${new URLSearchParams({
                    clusterId: clusterId,
                    labelKey: labelKey
                })}`
            },
            QUERY_RANGE: (clusterId: string, query: string, limit: number = 100, start: number, end: number) => {
                return `${protocol}://${SPIKE_HOST}/spike/search?${new URLSearchParams({
                    clusterId: clusterId,
                    query: query,
                    limit: limit,
                    start: start * 1000000,
                    end: end * 1000000
                })}`
            }
        },
        QUERY_MANAGER: {
            GET_LIST_URL: (userId) => {
                return `${protocol}://${SPIKE_HOST}/spike/query/list/${userId}`
            },
            EDIT: `${protocol}://${SPIKE_HOST}/spike/query/edit`,
            CREATE: `${protocol}://${SPIKE_HOST}/spike/query/add`,
            DELETE: `${protocol}://${SPIKE_HOST}/spike/query/remove`
        }
    },
    GET_TAIL_URL: function (clusterName,query, ts) {
        return `ws${allowHttps ? 's' : ''}://${TAIL_HOST}/?query=${encodeURI(query)}${!isNaN(ts) ? "&&start=" + ts : ''}`
    }
}
export const Clients = {
    AUTH: {
        CURRENT_USER: async (auth: Auth) => {
            try {
                let userResponse = await fetch(URL.SPIKE.AUTH.CURRENT_USER, {
                    headers: {...auth},
                    method: 'GET'
                })
                let json = await userResponse.json()
                console.log(json)
                let user = await extractApiData(json);
                console.log(user)
                return user
            } catch (e) {
                return Promise.reject(e)
            }
        }
    },
    CLUSTER: {
        LIST: async (auth: Auth) => {
            return new Promise(async (resolve, reject) => {
                try {
                    let request = {
                        headers: {
                            ...auth,
                            'Content-Type': 'application/json'
                        },
                        method: 'GET'
                    };
                    let clusterRes = await fetch(URL.SPIKE.CLUSTER.LIST, request)
                    let clusters = await clusterRes.json()
                    if (Array.isArray(clusters)) {
                        return resolve(clusters)
                    } else {
                        console.log(JSON.stringify(clusters))
                        let error = `expected response is of [] instead found ${JSON.stringify(clusters)}`;
                        console.error(error)
                        return reject(error)
                    }
                } catch (err) {
                    return reject(err)
                }
            })
        }
    },
    ALERTS: {
        UPDATE: async (auth: Auth, alertConfig: AlertConfigData) => {
            console.log(alertConfig)
            return new Promise(async (resolve, reject) => {
                try {
                    let updateResponse = await fetch(URL.SPIKE.ALERTS.UPDATE_CONFIG_FOR_CLUSTER, {
                        headers: {
                            ...auth,
                            'Content-Type': "application/json"
                        },
                        body: JSON.stringify(alertConfig),
                        method: 'POST',
                    })
                    return resolve(await updateResponse.json())
                } catch (err) {
                    return reject(err)
                }
            })
        },
        FETCH: async (auth: Auth, clusterId: string) => {
            return new Promise(async (resolve, reject) => {
                try {
                    let alertConfigResponse = await fetch(URL.SPIKE.ALERTS.CONFIG_FOR_CLUSTER_URL(clusterId), {
                        headers: {...auth},
                        method: 'GET'
                    })
                    let response = await alertConfigResponse.json();
                    if ('success' in response && response.success && 'data' in response) {
                        resolve(response.data)
                    } else {
                        if ('errorId' in response && 'message' in response) {
                            reject(response.message)
                        }
                    }
                } catch (e) {
                    reject(e)
                }
            })
        }
    },
    QUERY_MANAGER: {
        LIST: async (auth: Auth, userId: string) => {
            try {
                let input = URL.SPIKE.QUERY_MANAGER.GET_LIST_URL(userId);
                let listQueriesResponse = await fetch(input, {
                    headers: {...auth}
                });
                if (listQueriesResponse.ok) {
                    let json = await listQueriesResponse.json()
                    return await extractApiData(json)
                } else {
                    return Promise.reject(`request failed with status ${listQueriesResponse.ok}`)
                }
            } catch (e) {
                return Promise.reject(e)
            }
        },
        ADD: async (auth: Auth, searchQuery: SearchQuery) => {
            console.log(auth)
            let createQueryResponse = await fetch(URL.SPIKE.QUERY_MANAGER.CREATE, {
                headers: {...auth, 'Content-Type': "application/json"},
                method: 'POST',
                body: JSON.stringify(searchQuery),
            })
            if (createQueryResponse.ok) {
                let json = await createQueryResponse.json()
                if ('success' in json && json.success) {
                    return Promise.resolve()
                } else {
                    return Promise.reject(`error while adding query to user ${searchQuery} with error ${json.message}`)
                }
            }
        },
        EDIT: async (auth: Auth, searchQuery: SearchQuery) => {
            let editQueryResponse = await fetch(URL.SPIKE.QUERY_MANAGER.EDIT, {
                headers: {...auth, 'Content-Type': "application/json"},
                method: 'POST',
                body: JSON.stringify(searchQuery)
            })
            if (editQueryResponse.ok) {
                let json = await editQueryResponse.json()
                if ('success' in json && json.success) {
                    return Promise.resolve()
                } else {
                    return Promise.reject(`error while editing query to user ${searchQuery} with error ${json.message}`)
                }
            }
        },
        REMOVE: async (auth: Auth, searchQuery: SearchQuery) => {
            let query = {...searchQuery}
            if ('updatedAt' in query) {
                delete query.updatedAt
            }
            let request = {
                headers: {...auth, 'Content-Type': "application/json"},
                method: 'POST',
                body: JSON.stringify({...query})
            };
            let removeQueryResponse = await fetch(URL.SPIKE.QUERY_MANAGER.DELETE, request)
            if (removeQueryResponse.ok) {
                let json = await removeQueryResponse.json()
                if ('success' in json && json.success) {
                    return Promise.resolve()
                } else {
                    return Promise.reject(`error while removing query to user ${searchQuery} with error ${json.message}`)
                }
            }
        }
    },
    SEARCH: {
        FETCH_LABELS: async (auth: Auth, clusterId: string) => {
            let labelsResponse = await fetch(URL.SPIKE.SEARCH.GET_LABELS_URL(clusterId), {
                headers: {...auth},
                method: 'GET'
            });
            if (labelsResponse.ok) {
                let json = await labelsResponse.json()
                return await extractApiData(json)
            } else {
                return Promise.reject(labelsResponse.statusText)
            }
        },
        FETCH_LABEL_VALUES: async (auth: Auth, clusterId: string, labelKey: string) => {
            let labelValueResponse = await fetch(URL.SPIKE.SEARCH.GET_LABEL_VALUES(clusterId, labelKey), {
                headers: {...auth},
                method: 'GET'
            })
            if (labelValueResponse.ok) {
                let json = await labelValueResponse.json()
                return await extractApiData(json)
            } else {
                return Promise.reject(labelValueResponse.statusText)
            }
        },
        SEARCH_QUERY: async (auth, clusterId, query, start, end, limit) => {
            let queryResponse = await fetch(URL.SPIKE.SEARCH.QUERY_RANGE(clusterId, query, limit, start, end), {
                headers: {...auth},
                method: 'GET'
            });
            if (queryResponse.ok) {
                let json = await queryResponse.json()
                return await extractLogsFromApi(json)
            } else {
                return Promise.reject(queryResponse.statusText)
            }
        }
    }
}
