import {configureStore} from '@reduxjs/toolkit';
import explorerReducer from "../features/explorer/explorerSlice";
import alertSlice from "../features/alerts/alertsSlice"
import tailSlice from "../features/explorer/TailSlice";
import authSlice from "../features/authentication/AuthSlice";

export const store = configureStore({
    reducer: {
        explorer: explorerReducer,
        auth: authSlice,
        alert: alertSlice,
        tail: tailSlice
    },
});
