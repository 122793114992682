//@flow
import {DateTime} from "luxon";

export const TimeUnits = ['milliseconds', 'seconds', 'minutes', 'hour', 'days', 'weeks', 'months', 'years']

export function getTimeDiffToCurrent(time: number = DateTime.now().toMillis()) {
    let current = DateTime.now()
    let diff = DateTime.fromMillis(time).diff(current, TimeUnits)
    const ts = TimeUnits;
    for (let index = ts.length - 1; index >= 0; index--) {
        let unit = ts[index]
        let count = diff.get(unit);
        if (count > 0) {
            return count === 1 ? `in a ${unit.substr(0, unit.length - 1)}${unit === 'hour' ? 'r' : ''}` : `in ${count} ${unit}`
        } else if (count < 0) {
            return count === -1 ? `last ${unit.substr(0, unit.length - 1)}${unit === 'hour' ? 'r' : ''}` : `last ${count * -1} ${unit}`
        } else if (index === 0 || index === 1) {
            return 'Now'
        }
    }
}