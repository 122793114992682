import {Box, Button, Tab, Tabs} from "@mui/material";
import {useEffect, useState} from "react";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import AlertTargets, {CreateAlertTargetDialog} from "./AlertTargets";
import {useDispatch, useSelector} from "react-redux";
import {alertConfigDataSelector, initialiseConfig, updateConfig,} from "./alertsSlice";
import AlertsDashboard, {CreateAlertDialog} from "./AlertsDashboard";
import {
    emailSelector,
    nameSelector,
    tokenSelector,
    uidSelector,
    userProfileSelector
} from "../authentication/AuthSlice";
import {AddSharp} from "@mui/icons-material";
import {selectClusters} from "../explorer/explorerSlice";

export default function AlertManager() {
    const [tabIndex, setTabIndex] = useState('0')
    const dispatch = useDispatch()
    const uid = useSelector(uidSelector)
    const token = useSelector(tokenSelector)
    const name = useSelector(nameSelector)
    const alertConfig = useSelector(alertConfigDataSelector)
    const email = useSelector(emailSelector)
    const userProfile = useSelector(userProfileSelector)
    const [openAlertRuleDialog, isOpenAlertRuleDialog] = useState(false)
    const [openAlertTargetDialog, isOpenAlertTargetDialog] = useState(false)
    const clusters = useSelector(selectClusters)

    useEffect(async () => {
        dispatch(initialiseConfig({'auth-token': token, email: email, uid: uid}))
    }, [])
    useEffect(() => {
        console.log(alertConfig)
    }, [alertConfig])
    return <>
        <Box>
            <TabContext value={tabIndex}>
                <TabList onChange={(event, index) => {
                    setTabIndex(index)
                }} aria-label="basic tabs example">
                    <Tab label="Rules" value={"0"}/>
                    <Tab label="Targets" value={"1"}/>
                </TabList>
                <TabPanel value={"0"}>
                    <Box sx={{width: '100%'}} display={"flex"} justifyContent={"flex-end"}>
                        <Button startIcon={<AddSharp/>} onClick={() => {
                            isOpenAlertRuleDialog(true)
                        }}>Add New Rule</Button>
                    </Box>
                    <CreateAlertDialog clusters={clusters} userProfile={userProfile} open={openAlertRuleDialog}
                                       onSave={(config) => {
                                           let clusterId = config.clusterId
                                           if (clusterId === undefined) {
                                               alert("clusterId cannot be undefined")
                                               return
                                           }
                                           let updatedConfig = alertConfig.find(configData => {
                                               return configData.clusterId === clusterId
                                           })
                                           updatedConfig = {...updatedConfig}
                                           if (updatedConfig === undefined||Object.keys(updatedConfig).length===0) {
                                               let cluster = clusters.find(cluster => {
                                                   return cluster.id === clusterId
                                               })
                                               if (cluster === undefined || !('cluster_name' in cluster)) {
                                                   return;
                                               }
                                               updatedConfig = {
                                                   companyId: userProfile.companyId,
                                                   clusterId: clusterId,
                                                   clusterName: cluster.cluster_name,
                                                   rules: [],
                                                   targets: []
                                               }
                                           }
                                           let rules = [...updatedConfig.rules];
                                           rules.push({
                                               id: userProfile.companyId + "_" + userProfile.userId + "_" + Date.now(),
                                               name: config.name,
                                               query: config.query,
                                               keys: config.keys,
                                               kvKeys: config.kvKeys,
                                               kvValues: config.kvValues,
                                               alertOnCount: config.alertOnCount,
                                               alertDuration: config.alertDuration,
                                               summary: config.summary,
                                               description: config.description
                                           })
                                           updatedConfig.rules = rules
                                           dispatch(updateConfig({
                                               auth: {uid: uid, email: email, 'auth-token': token},
                                               alertConfig: updatedConfig
                                           }))
                                       }} onClose={() => {
                        isOpenAlertRuleDialog(false)
                    }} ruleId={alertConfig.companyId + "_" + alertConfig.companyId + "_" + Date.now()}/>

                    {alertConfig.map((config, index) => {
                        console.log(config)
                        return config !== undefined && <Box key={index}>
                            <AlertsDashboard alertConfig={config} key={index}
                                             auth={{uid: uid, name: name, email: email, 'auth-token': token}}/>
                        </Box>
                    })}
                </TabPanel>
                <TabPanel value="1">
                    <Box sx={{width: '100%'}} display={"flex"} justifyContent={"flex-end"}>
                        <Button startIcon={<AddSharp/>} onClick={() => {
                            isOpenAlertTargetDialog(true)
                        }}>New Target</Button>
                    </Box>
                    <CreateAlertTargetDialog clusters={clusters}
                                             targetId={userProfile.companyId + "_" + userProfile.userId + "_" + Date.now()}
                                             open={openAlertTargetDialog}
                                             onClose={() => {
                                                 isOpenAlertTargetDialog(false)
                                             }} onSave={(target) => {
                        let clusterId = target.clusterId
                        if (clusterId === undefined) {
                            alert("clusterId cannot be undefined")
                            return
                        }
                        let updatedConfig = alertConfig.find(configData => {
                            return configData.clusterId === clusterId
                        })

                        if (updatedConfig === undefined) {
                            let cluster = clusters.find(cluster => {
                                return cluster.id === clusterId
                            })
                            if (cluster === undefined || !('cluster_name' in cluster)) {
                                return;
                            }
                            updatedConfig = {
                                companyId: userProfile.companyId,
                                clusterId: clusterId,
                                clusterName: cluster.cluster_name,
                                rules: [],
                                targets: []
                            }
                        }else {
                            updatedConfig={...updatedConfig}
                        }
                        let targets = [...updatedConfig.targets]
                        targets.push({
                            targetId: userProfile.companyId + "_" + userProfile.userId + "_" + Date.now(),
                            type: target.type,
                            authKey: target.authKey,
                            targetUrl: target.targetUrl,
                            title: target.title,
                            description: target.description
                        })
                        updatedConfig.targets = targets
                        dispatch(updateConfig({
                            auth: {uid: uid, email: email, 'auth-token': token},
                            alertConfig: updatedConfig
                        }))

                    }}></CreateAlertTargetDialog>
                    {
                        alertConfig.map((config, index) => {
                            console.log(config)
                            return <AlertTargets key={index} alertConfig={config}
                                                 auth={{
                                                     uid: uid,
                                                     name: name,
                                                     email: email,
                                                     'auth-token': token
                                                 }}></AlertTargets>
                        })
                    }

                </TabPanel>

            </TabContext>
        </Box>
    </>
}