import {Box, Divider, TextField, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import LabelList from "./LabelList";

export default function SideLabelPanel({labels, onFilterClick}) {
    const [filter, setFilter] = useState('')
    const [filteredLabels, setFilteredLabels] = useState([])
    useEffect(() => {
        if (filter !== '') {
            let filtered = {}
            let filteredKeys = Object.keys(labels).filter(value => {
                return value.includes(filter)
            })
            filteredKeys.forEach(value => {
                filtered[value] = labels[value]
            })
            setFilteredLabels(filtered)
        } else {
            setFilteredLabels(labels)
        }
    }, [filter, labels])

    return <>
        <Box  width={'100%'} paddingLeft={'0.5rem'}>
            
            <TextField sx={{marginTop: '10px',marginTop:'2px'}} placeholder={"Label"} fullWidth size={"small"} value={filter} onChange={(event) => {
                setFilter(event.target.value)
            }}/>
            {/* <Box flexGrow={1}>
                <Typography variant={"h6"}>{`${Object.keys(labels).length} Labels`}</Typography>
            </Box> */}
            <LabelList labels={filteredLabels} onFilterClick={(value) => {
                onFilterClick(value)
            }
            }/>
        </Box>

    </>
}