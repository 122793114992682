

import { Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";

export function extractStats(logs) {
  let stats = {};
  logs.forEach((logSection, index) => {
    logSection.logs.forEach((log, index) => {
      let ts = log._ts / 1000000;

      if (ts in stats) {
        stats[ts] = stats[ts] + 1;
      } else {
        stats[ts] = 1;
      }
    });
  });
  return Object.keys(stats).map((stat, index) => {
    return { date: stat, count: stats[stat] ,index:index };
  });
}

export default function StatsGraph({ logs }) {
  const [stats, setStats] = useState([]);
  useEffect(() => {
    let computedStats = extractStats(logs);

    setStats(computedStats);
  }, [logs]);
  return (
    <>
      <ResponsiveContainer
        width={"95%"}
        minHeight={"200px"}
        maxHeight={"200px"}
      >
        <BarChart width={500} height={300} data={stats}>
       
          {stats.length > 0 && (
             
            <XAxis
              minTickGap={4}
              dataKey="date"
               domain={[stats[0].date, stats[stats.length - 1].date<4 ? 4 :stats[stats.length - 1].date]}
             
              scale="band"
              tickMargin={3}
              tick={{ fontSize: 13 }}
              tickFormatter={(val) => {
                let mills = parseInt(val);
                // return DateTime.fromMillis(mills).toFormat(
                //   "yyyy/MM/dd HH:mm:ss"
                // );
                return DateTime.fromMillis(mills).toFormat(
                  "HH:mm:ss"
                );
              }}
            />
          )}
          {/* /> */}
          
          <YAxis dataKey="count"  tickCount={5} allowDecimals={false}/>
          {/* <YAxis  orientation='right' /> */}
          <Legend  />
          <Tooltip/>
          <Bar name="Occurances"  dataKey="count" fill={"#8884d8"}  barCategoryGap={10}/>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

