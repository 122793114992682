import QueryForm from "../../component/QueryForm";
import {useDispatch, useSelector} from "react-redux";
import {
    acquireLabels, acquireQueries, addQuery, fetchLogs, onFiltersAdded, onFiltersRemoved,
    onFromDateTimeRangeChange, onQueryChange,
    onToDateTimeRangeChange, selectClusterId, selectFilters, selectFrom,
    selectLabels, selectLoading, selectLogs,
    selectQuery, selectTo,
} from "./explorerSlice";
import {Box, Button, Divider, Grid, LinearProgress} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import LabelForm from "../../component/LabelForm";
import LogsTableVIew from "../../component/LogsTableVIew";
import StatsGraph from "../../component/StatsGraph";
import SideLabelPanel from "../../component/SideLabelPanel";
import NoLogs from "../../component/NoLogs";
import Tail from "./Tail";
import {emailSelector, tokenSelector, uidSelector, userIdSelector} from "../authentication/AuthSlice";
import {SaveSharp} from "@mui/icons-material";
import QuerySaveModal from "../../component/QuerySaveModal";
import {reconstructQuery} from "../../lib/queryUtils";

export default function Explorer() {
    const dispatch = useDispatch()
    const uid = useSelector(uidSelector)
    const token = useSelector(tokenSelector)
    const email = useSelector(emailSelector)
    const query = useSelector(selectQuery)
    const labels = useSelector(selectLabels)
    const filters = useSelector(selectFilters)
    const logs = useSelector(selectLogs)
    const loading = useSelector(selectLoading)
    const selectedCluster = useSelector(selectClusterId)
    const [logTableHeight, setLogTableHeight] = useState(0)
    const [tailing, setTailing] = useState(false)
    const [logWindow, setLogWindow] = useState('search')
   
    const userId = useSelector(userIdSelector)
    const fromTimeRecord = useSelector(selectFrom)
    const toTimeRecord = useSelector(selectTo)
    const node = useRef(null)
    useEffect(() => {
        console.log("&&&& useeffect",selectedCluster,node,node.current)
        if (selectedCluster !== -1 || selectedCluster !== undefined)
            if (node !== null && node.current !== null) {
                let height = window.innerHeight - node?.current.clientHeight - 10;
                setLogTableHeight(height)
            }
    }, [selectedCluster, node])

    useEffect(() => {
        dispatch(acquireQueries({auth: {uid: uid, email: email, 'auth-token': token}}))
    }, [])
    useEffect(() => {
        if ((selectedCluster !== -1 && selectedCluster !== undefined)) {
            dispatch(acquireLabels({
                auth: {uid: uid, 'auth-token': token, email: email}, clusterId: selectedCluster
            }))
        }
    }, [selectedCluster])
    return (
        <>
            {loading && <LinearProgress/>}
            {(selectedCluster === -1 || selectedCluster === undefined) ? <>
                <a href={'https://spike.episilia.net/spike/dashboard'}>click here to create cluster</a>
            </> : <>
                <Grid container spacing={2} paddingTop={'0.5rem'} paddingRight={'0.5rem'}>
                    <Grid item padding={'0.5rem'} sm={2}>
                        <Box height={'100vh'}>
                            <SideLabelPanel filters={filters} labels={labels} onFilterClick={(filter) => {
                                dispatch(onFiltersAdded(filter))
                            }}/>
                        </Box>
                    </Grid>
                    <Grid item sm={10}>
                        <Box ref={node}>
                            {/* <Box display={"flex"} flexGrow={1} justifyContent={"flex-end"}> */}
                                {/* <Button variant={"contained"} sx={{marginRight: '0.5rem'}} size={"small"}
                                        startIcon={<SaveSharp/>}
                                        onClick={(event) => {
                                            setOpenQuerySaveModal(true)
                                        }}>Save Query</Button>
                                <LabelForm labels={labels} filters={filters} onFilterAdded={(filter) => {
                                    dispatch(onFiltersAdded(filter))
                                }} onFilterDeleted={(filter) => {
                                    dispatch(onFiltersRemoved(filter))
                                }}/> */}
                            {/* </Box> */}

                            <QueryForm fromTimeRecord={fromTimeRecord} toTimeRecord={toTimeRecord} query={query}
                                       tailing={tailing}
                                        filters={filters}
                                        labels={labels}

                                       onTailing={() => {
                                           setTailing(true)
                                           setLogWindow('tail')
                                       }}
                                       onStopTailing={() => {
                                           setTailing(false)
                                           setLogWindow('search')
                                       }}
                                       onQueryChange={(change) => dispatch(onQueryChange(change))}
                                       onFromDateChange={(change) => {
                                           dispatch(onFromDateTimeRangeChange(change))
                                       }}
                                       onToDateChange={(change) => dispatch(onToDateTimeRangeChange(change))}
                                       onSearchClicked={() => {
                                           if (logWindow === 'tail' || tailing) {
                                               setTailing(false)
                                               setLogWindow('search')
                                           }

                                           dispatch(fetchLogs({
                                               auth: {
                                                   uid: uid,
                                                   "auth-token": token,
                                                   email: email,
                                               },
                                               clusterId: selectedCluster,
                                               start: fromTimeRecord.epoch,
                                               end: toTimeRecord.epoch,
                                               query: query,
                                               limit: 1000
                                           }))
                                       }}
                            />
                        </Box>

                        {/* <Divider sx={{marginTop: '0.5rem'}}/> */}
                        {logWindow === 'search' && <>
                            {logs?.length > 0 ?
                                <Box marginTop={'0.5rem'} sx={{maxHeight: logTableHeight, overflow: 'auto', borderWidth:1, borderColor:'black', borderStyle:'solid', paddingLeft:10, paddingTop: 5}}
                                     height={logTableHeight}>
                                    <StatsGraph logs={logs}/>
                                    <LogsTableVIew logs={logs}/>
                                </Box> : <NoLogs/>}
                        </>}
                        {logWindow === 'tail' && <>
                            <Tail maxHeight={logTableHeight} query={reconstructQuery(filters,query)}/>
                        </>}
                    </Grid>
                </Grid>
            </>}
            {/* <QuerySaveModal userId={userId} open={saveQueryModal} query={query} filters={filters}
                            onSave={(queryId, query, queryName, userId, shared) => {
                                dispatch(addQuery({
                                    auth: {uid: uid, email: email, 'auth-token': token}, userQuery: {
                                        query: query,
                                        queryName: queryName,
                                        userId: userId,
                                        shared: shared,
                                        tags: "",
                                    }
                                }))
                            }} onClose={() => {
                setOpenQuerySaveModal(false)
            }}/> */}
        </>
    )
}